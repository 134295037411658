/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
import { useWindowSize } from '@folklore/hooks';
import { DataProvider } from '@micromag/data';
import '@micromag/intl/locale/fr';
import Viewer from '@micromag/viewer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';

import styles from '../../styles/partials/micromag.module.scss';

const propTypes = {
    micromag: PropTypes.shape({}),
    viewerMenuHeader: PropTypes.node,
    currentScreenId: PropTypes.string,
    paused: PropTypes.bool,
    fullscreen: PropTypes.bool,
    autoFullscreen: PropTypes.bool,
    withoutPlaybackControls: PropTypes.bool,
    basePath: PropTypes.string,
    withoutRouter: PropTypes.bool,
    // onStart: PropTypes.func,
    onClose: PropTypes.func,
    onEnd: PropTypes.func,
    onScreenChange: PropTypes.func,
    onMenuChange: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    micromag: {},
    viewerMenuHeader: null,
    currentScreenId: null,
    paused: false,
    fullscreen: false,
    autoFullscreen: false,
    withoutPlaybackControls: false,
    basePath: null,
    withoutRouter: true,
    // onStart: null,
    onClose: null,
    onEnd: null,
    onScreenChange: null,
    onMenuChange: null,
    className: null,
};

function Micromag({
    micromag,
    viewerMenuHeader,
    currentScreenId,
    paused,
    fullscreen: initialFullscreen,
    autoFullscreen,
    withoutPlaybackControls,
    basePath,
    withoutRouter,
    className,
    // onStart: customOnStart,
    onClose: customOnClose,
    onEnd: customOnEnd,
    onScreenChange,
    onMenuChange,
}) {
    const googleApiKey = null;
    const micromagApiBaseUrl = 'https://microm.ag/api';
    const { organisation = null } = micromag || {};
    const { branding = null } = organisation || {};
    const disableScroll = false;
    const { width = 0, height = 0 } = useWindowSize();
    const [landscape, setLandscape] = useState(width > height);
    const [fullscreen, setFullscreen] = useState(autoFullscreen && !landscape && initialFullscreen);

    const onClose = useCallback(() => {
        if (autoFullscreen && fullscreen) {
            setFullscreen(false);
        }
        if (customOnClose !== null) {
            customOnClose();
        }
    }, [autoFullscreen, fullscreen, setFullscreen, customOnClose]);

    // const onStart = useCallback(() => {
    //     if (autoFullscreen && !landscape) {
    //         setFullscreen(true);
    //     }
    //     if (customOnStart !== null) {
    //         customOnStart();
    //     }
    // }, [autoFullscreen, setFullscreen, landscape, customOnStart]);

    const onEnd = useCallback(() => {
        if (autoFullscreen && fullscreen) {
            setFullscreen(false);
        }
        if (customOnEnd !== null) {
            customOnEnd();
        }
    }, [autoFullscreen, fullscreen, setFullscreen, customOnEnd]);

    const onViewModeChange = useCallback(
        ({ landscape: isLandscape = false }) => {
            setLandscape(isLandscape);
            if (autoFullscreen && !isLandscape) {
                setFullscreen(true);
            }
        },
        [autoFullscreen, setLandscape, setFullscreen],
    );

    useEffect(() => {
        if (autoFullscreen) {
            disableScroll(fullscreen);
        }
    }, [autoFullscreen, fullscreen]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.fullscreen]: fullscreen,
                    [className]: className !== null,
                },
            ])}
        >
            <DataProvider apiBaseUrl={micromagApiBaseUrl}>
                <Viewer
                    story={micromag}
                    paused={paused}
                    theme={branding}
                    locale="fr"
                    visitor={null}
                    memoryRouter={basePath === null}
                    withoutRouter={withoutRouter}
                    basePath={basePath}
                    screen={currentScreenId}
                    closeable={(autoFullscreen && fullscreen) || customOnClose !== null}
                    googleApiKey={googleApiKey}
                    onViewModeChange={onViewModeChange}
                    onScreenChange={onScreenChange}
                    onMenuChange={onMenuChange}
                    onClose={onClose}
                    onEnd={onEnd}
                    withoutPlaybackControls={withoutPlaybackControls}
                    withNavigationHint
                    pathWithIndex
                    menuHeader={viewerMenuHeader}
                />
            </DataProvider>
        </div>
    );
}

Micromag.propTypes = propTypes;
Micromag.defaultProps = defaultProps;

export default React.memo(Micromag);
