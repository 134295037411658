import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useLocation } from 'wouter';

import Micromag from '../partials/Micromag';

// import PageMeta from '../partials/PageMeta';
import styles from '../../styles/pages/home.module.scss';

const propTypes = {
    screen: PropTypes.string,
    micromag: PropTypes.shape({}),
};

const defaultProps = {
    screen: null,
    micromag: null,
};

function HomePage({ screen, micromag }) {
    const [, setLocation] = useLocation();
    const baseUrl = '';
    const { components: screens = [] } = micromag || {};

    const pathScreenId = screen;
    const pathScreenIndex =
        pathScreenId !== null && pathScreenId.match(/^[0-9]+$/) !== null
            ? parseInt(pathScreenId, 10) - 1
            : null;
    const screenByPathId =
        pathScreenId !== null
            ? screens.find(({ id: screenId }) => screenId === pathScreenId) || null
            : null;
    const screenByPathIndex = pathScreenIndex !== null ? screens[pathScreenIndex] || null : null;
    const currentScreen =
        screenByPathId ||
        screenByPathIndex ||
        (pathScreenId === '' ? screens[0] : null) ||
        screens[0] ||
        null;

    const onScreenChange = useCallback(
        (newScreen) => {
            const newScreenIndex =
                screens.findIndex(({ id: screenId }) => screenId === newScreen.id) || null;
            setLocation(
                newScreenIndex !== null ? `${baseUrl}/${newScreenIndex + 1}` : `${baseUrl}/1`,
            );
        },
        [screens, setLocation],
    );

    return (
        <div className={styles.container}>
            {/* <PageMeta /> */}
            <Micromag
                micromag={micromag}
                basePath={baseUrl}
                currentScreenId={currentScreen !== null ? currentScreen.id : null}
                className={styles.micromag}
                onScreenChange={onScreenChange}
            />
        </div>
    );
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
