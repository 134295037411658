import { getJSON } from '@folklore/fetch';
import { RoutesProvider } from '@folklore/routes';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Router } from 'wouter';

import Routes from './Routes';

import '../styles/styles.scss';

import initialMicromag from '../data/micromag.json';

const propTypes = {
    intl: PropTypes.shape({
        locale: PropTypes.string,
        messages: PropTypes.oneOfType([
            PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
            PropTypes.objectOf(PropTypes.string),
        ]),
    }),
    routes: PropTypes.objectOf(PropTypes.string),
    initialMicromag: PropTypes.shape({}),
    micromag: PropTypes.string,
    micromagUrl: PropTypes.string,
};

const defaultProps = {
    intl: null,
    routes: {},
    initialMicromag,
    micromag: null,
    micromagUrl: null,
};

function parseFonts20ans(micromag = null) {
    if (micromag !== null) {
        const jsonMicromag = JSON.stringify(micromag);
        const replaced = jsonMicromag
            .replace(/Arial/g, 'omnes-semicond')
            .replace(/Verdana/g, 'omnes-semicond');
        const finalMicromag = JSON.parse(replaced);
        // console.log('finalMicromag', finalMicromag);
        return finalMicromag;
    }
    return micromag;
}

function App({ intl, routes, micromag: micromagAlias, micromagUrl }) {
    const { locale = 'fr', messages = null } = intl || {};
    const finalMessages = messages !== null && messages[locale] ? messages[locale] : messages;
    const [micromag, setMicromag] = useState();

    // console.log(micromagAlias, micromagUrl);

    // Get json from the api
    useEffect(() => {
        getJSON(micromagUrl)
            .then((newMicromag) => {
                if (micromagAlias === '20ans') {
                    setMicromag(parseFonts20ans(newMicromag));
                } else {
                    setMicromag(newMicromag);
                }
            })
            .catch(() => {
                // Load failure
                // setMicromag(null);
            });
    }, [micromagUrl, micromagAlias, setMicromag]);

    return (
        <IntlProvider locale={locale} messages={finalMessages || {}}>
            <Router>
                <RoutesProvider routes={routes}>
                    <Routes micromag={micromag} />
                </RoutesProvider>
            </Router>
        </IntlProvider>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
