import { useRoutes } from '@folklore/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'wouter';

import MainLayout from './layouts/Main';
import HomePage from './pages/Home';

import '../styles/styles.scss';

const propTypes = {
    micromag: PropTypes.shape({}),
};

const defaultProps = {
    micromag: null,
};

function Routes({ micromag }) {
    const routes = useRoutes() || {};
    return (
        <Switch>
            <MainLayout>
                <Route path={routes.home || '/'}>
                    <HomePage micromag={micromag} />
                </Route>
                <Route path="/:screen">
                    {({ screen }) => <HomePage micromag={micromag} screen={screen} />}
                </Route>
            </MainLayout>
        </Switch>
    );
}

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
